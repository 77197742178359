<template>
    <div class="row">
        <div class="col-3">
            <div class="text-center fw-bold mb-3">კატეგორიები</div>
            <f-nested :first="true" @update-list="updateList($event)" :selected="values.categories" :items="categories"></f-nested>
        </div>
        <div class="col">
            <div class="text-center fw-bold mb-3">მახასიათებლები</div>
            <div class="row">
                <template v-for="item in specificationGroups.input">
                    <div class="col-12 mb-4" v-if="inCategory(item.categories)">
                        <specification-input :locales="locales" :value="values.specifications[item.id]"
                                             :specification="item"></specification-input>
                    </div>
                </template>

                <template v-for="item in specificationGroups.number">
                    <div class="col-12 mb-4" v-if="inCategory(item.categories)">
                        <specification-number :locales="locales" :value="values.specifications[item.id]"
                                              :specification="item"></specification-number>
                    </div>
                </template>

                <template v-for="item in specificationGroups.textarea">
                    <div class="col-12 mb-4" v-if="inCategory(item.categories)">
                        <specification-textarea :locales="locales" :value="values.specifications[item.id]"
                                                :specification="item"></specification-textarea>
                    </div>
                </template>

                <template v-for="item in specificationGroups.select">
                    <div class="col-12 mb-4" v-if="inCategory(item.categories)">
                        <specification-list :value="values.specifications[item.id]" :specification="item"></specification-list>
                    </div>
                </template>

                <template v-for="item in specificationGroups.checkbox">
                    <div class="col-12 col-lg-6 mb-4" v-if="inCategory(item.categories)">

                        <specification-checkbox :value="values.specifications[item.id]"
                                                :specification="item"></specification-checkbox>
                    </div>
                </template>

                <template v-for="item in specificationGroups.radio">
                    <div class="col-12 col-lg-6 mb-4" v-if="inCategory(item.categories)">
                        <specification-radio :value="values.specifications[item.id]" :specification="item"></specification-radio>
                    </div>
                </template>
            </div>
        </div>
    </div>


</template>

<script>
import SpecificationInput from "./SpecificationInput";
import SpecificationNumber from "./SpecificationNumber";
import SpecificationList from "./SpecificationList";
import SpecificationCheckbox from "./SpecificationCheckbox";
import SpecificationRadio from "./SpecificationRadio";
import FNested from "../inputs/FNested";
import SpecificationTextarea from "./SpecificationTextarea.vue";

export default {
    name: "SpecificationPicker",
    components: {
        SpecificationTextarea,
        FNested,
        SpecificationRadio,
        SpecificationCheckbox,
        SpecificationList,
        SpecificationNumber,
        SpecificationInput,
    },
    props: {
        categories: {
            type: Array,
            default: []
        },
        specifications: {
            type: Object,
            default: []
        },
        values: {
            type: Object,
            default: {
                categories: [],
                specifications: []
            }
        }
    },
    data() {
        return {
            locales: ['ka', 'en'],
            categoriesSelected: [],
            specificationsSelected: [],
            specificationGroups: {
                input: [],
                textarea: [],
                number: [],
                checkbox: [],
                radio: [],
                select: []
            },
        }
    },
    methods: {
        updateList(list) {
            this.categoriesSelected = [];
            list.forEach(item => {
                let selected = []
                if (item.checked) {
                    this.categoriesSelected.push(item);
                }
                if (item.items.length) {
                    this.categoriesSelected = [...this.filterCategories(item.items, []), ...this.categoriesSelected];
                }
            })
        },
        filterCategories(list, found) {
            found = [...found, ...list.filter(item => item.checked)];
            return found;
        },
        updateCategorySelectedList(list, selected) {
            if (!selected.length || !list.length) return [];
            let newSelected = selected;
            let found = [];
            list.forEach(item => {
                const index = newSelected.indexOf(item.id);
                if (index > -1) {
                    item.checked = true;
                    found.push(item);
                    newSelected.splice(index, 1);
                }
                if (item.items.length > 0) {
                    found = [...this.updateCategorySelectedList(item.items, newSelected), ...found];
                }
            });
            return found;
        }

    },
    computed: {
        inCategory() {
            let vm = this;
            return function (categories) {
                return vm.categoriesSelected.some(r => categories.indexOf(r.slug) >= 0)
            }
        },
    },
    mounted() {
        this.specificationGroups = this.specifications;
        this.categoriesSelected = this.updateCategorySelectedList(this.categories, this.values.categories);
    }
}
</script>

<style scoped></style>
