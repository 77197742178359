<template>
    <button type="button" class="btn btn-outline-dark" data-bs-toggle="modal" @click="load()"
            :data-bs-target="'#file-picker-'+id">
        <i class="bi bi-folder"></i> {{ $t('file.picker.choose') }}
    </button>


    <template v-if="multiple">
        <ul class="list-unstyled">
            <li v-for="(file, index) in picked" class="img-preview d-flex mb-2">
                <input type="hidden" :name="name+(multiple?'['+index+']':'')" :value="file.id">
                <span class="d-inline-flex flex-column me-2">
                    <i class="bi bi-arrow-up border p-2 mb-2" role="button" @click="changePosition(index,index-1)"
                       v-if="index !== 0"></i>
                    <i class="bi bi-arrow-down border p-2" role="button"
                       @click="changePosition(index,index+1)"
                       v-if="index + 1 !== picked.length"></i>
                </span>
                <div>
                    <div class="close" @click="unSelect(file)">
                        <i class="bi bi-x-lg text-danger"></i>
                    </div>
                    <img :src="file.url" alt="" class="img-thumbnail">
                </div>
            </li>
        </ul>
    </template>
    <template v-else-if="picked.length">
        <input type="hidden" :name="name" :value="picked[0].id">
        <div class="img-preview">
            <div class="close" @click="picked = []">
                <i class="bi bi-x-lg text-danger"></i>
            </div>
            <img :src="picked[0].url" alt="" class="img-thumbnail">
        </div>
    </template>


    <div class="modal fade" :id="'file-picker-'+id" tabindex="-1" :aria-labelledby="id"
         aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content border">
                <div class="modal-header p-2">
                    <div>
                        <label class="btn btn-primary me-1" v-if="multiple">
                            <i class="bi bi-check2-all"></i> Select all
                            <input type="checkbox" ref="selectInput" class="d-none" @change="selectAll">
                        </label>
                        <button class="btn btn-primary me-1" @click.prevent="getFiles">
                            <i class="bi bi-arrow-clockwise"></i> Refresh
                        </button>
                        <file-picker-file-upload :dir-id="dirId" v-on:uploadDone="getFiles"></file-picker-file-upload>
                    </div>
                    <div class="me-1">
                        <file-picker-new-folder @create="updateTree($event)"></file-picker-new-folder>
                    </div>
                    <div class="me-1">
                        <div class="input-group">
                            <input type="text" class="form-control bg-transparent text-light "
                                   ref="myInput"
                                   @keyup.enter.prevent="search"
                                   placeholder="Search...">

                            <button class="btn btn-primary" @click.prevent="search">
                                <i class="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="flex-grow-1 text-end">
                        <button type="button" class="btn btn-light ms-2" data-bs-dismiss="modal">
                            <i class="bi bi-x-lg"></i>
                        </button>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="row gx-0">
                        <div class="col-3 border-end fp-folders py-0">
                            <file-picker-storage v-on:selectStorage="storage = $event"
                                                 v-on:select-folder="selectFolder($event)"
                                                 :storages="storages"></file-picker-storage>
                        </div>
                        <div class="col p-0">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb px-3 py-2 mb-0  border-bottom">
                                    <li class="breadcrumb-item" role="button" v-for="item in breadcrumbs">
                                        {{ item }}
                                    </li>
                                </ol>
                            </nav>
                            <div class="row gx-1 p-2 fp-files me-1">
                                <div class="col-6 col-md-4 col-lg-3 col-xl-2 mb-3" v-for="(file, i) in files">
                                    <file-picker-file :file="file" :i="i" v-on:select="select($event)">
                                    </file-picker-file>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilePickerFile from "./FilePickerFile";
import FilePickerStorage from "./FilePickerStorage";
import FilePickerFileUpload from "./FilePickerFileUpload";
import FilePickerNewFolder from "./FilePickerNewFolder";

export default {
    name: "FilePicker",
    components: {FilePickerNewFolder, FilePickerFileUpload, FilePickerStorage, FilePickerFile},
    props: {
        name: {
            type: String,
            default: 'image'
        },
        multiple: {
            types: Boolean,
            default: false
        },
        images: {
            type: [Object, Array, String],
            default: null
        }
    },
    data() {
        return {
            firstTime: true,
            storage: null,
            chosen: '',
            breadcrumbs: ['Breadcrumbs', 'Coming', 'Soon'],
            storages: {},
            files: [],
            picked: [],
            dirId: null
        }
    },
    mounted() {
        if (this.images) {
            if (Array.isArray(this.images)) {
                this.picked = this.images;
            } else if (typeof this.images === "object") {
                this.picked.push(this.images);
            }
        }
    },
    methods: {
        load() {
            if (this.firstTime) {
                this.getStorages();
                this.firstTime = false;
            }
            this.getFiles();
        },
        getStorages() {
            axios.get('/infy/filemanager/folder/tree').then((tree) => {
                this.storages = tree.data;
                this.storage = Object.keys(this.storages)[0];
            }).catch((err) => {
                this.$toast.error(err.message);
            })
        },
        getFile() {
            this.files = [];
            axios.get('/infy/filemanager/list').then((files) => {
                this.files = files.data;
            }).catch((err) => {
                this.$toast.error(err.message);
            })
        },
        getFiles() {
            this.files = [];
            axios.get('/infy/filemanager/list', {
                params: {
                    dir_id: this.dirId
                }
            }).then((files) => {
                this.files = files.data;
            }).catch((err) => {
                this.$toast.error(err.message);
            })
        },
        search() {
            this.$toast.info('Coming soon');
        },
        select(r) {
            let file = this.files.find((file, i) => i === r.file);

            if (r.selected) {
                if (this.multiple) {
                    this.picked.push(file)
                } else {
                    this.picked = [file];
                }
            } else {
                this.picked = this.picked.filter(f => f.id !== file.id);
                this.$refs.selectInput.checked = false;
            }
            this.files.map((file, i) => file.selected = i === r.file ? r.selected : (this.multiple ? file.selected : false));
        },
        selectAll() {
            this.files.map(file => file.selected = this.$refs.selectInput.checked);
        },
        selectFolder(folder) {
            this.dirId = folder.id;
            this.getFiles();
        },
        changePosition(cur, pos) {
            if (pos >= this.picked.length) {
                let k = pos - this.picked.length + 1;
                while (k--) {
                    this.picked.push(undefined);
                }
            }
            this.picked.splice(pos, 0, this.picked.splice(cur, 1    )[0]);
        },
        updateTree(result) {
            result && this.getStorages();
        },
        unSelect(file){
            this.picked = this.picked.filter(f => f.id !== file.id);
        }
    },
    computed: {
        id() {
            return this.name;
        }
    },
}
</script>

<style scoped lang="scss">
.fp-folders {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.fp-files {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.img-preview {
    max-height: 300px;
    min-height: 150px;

    img {
        height: 100% !important;
        max-height: 300px !important;
    }
}
</style>
