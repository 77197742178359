<template>
    <button class="btn btn-danger" @click="showModal">
        <i class="bi bi-trash"></i>
    </button>

    <div class="modal fade" :id="modalId" aria-hidden="true" v-if="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('delete.modal.title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t('delete.modal.body') }}</p>
                    <p class="fs-3">{{ title }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        {{ $t('delete.modal.close') }}
                    </button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteEntry">
                        <i class="bi bi-trash"></i> {{ $t('delete.modal.delete') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {nextTick} from "vue";

export default {
    name: 'DeleteEntry',
    props: ['route', 'slug', 'title'],
    emits: ['update', 'delete'],
    data() {
        return {
            modal: false,
            rand: Math.floor(Math.random() * 1000).toString(),
            modalId: `delete${this.rand}${(this.slug + '').replace(/ /g, '+')}`
        }
    },
    methods: {
        showModal() {
            this.modal = true;
            nextTick(() => {
                const modal = new bootstrap.Modal(`#${this.modalId}`)
                modal.show();
            })

        },
        deleteEntry() {
            this.modal = false;
            this.$emit("update", 'loading');
            if (this.route) {
                axios.post(this.route, {slug: this.slug}).then(response => {
                    if (response.data.result) {
                        this.$emit("update", 'finish');
                        this.$emit("delete", this.slug);
                        return this.$toast.success(this.$t('layout.success'))
                    }
                    this.$emit("update", 'false');
                    this.$toast.error(this.$t('layout.unsuccessful'))
                })
            } else {
                this.$emit("update", 'finish');
                this.$emit("delete", this.slug);
            }
        }
    }
}
</script>

<style scoped>

</style>
