<template>
    <label class="fw-bold">{{ specification.name }}</label>
    <div class="d-flex gap-3">
        <template v-for="locale in locales">
            <f-text class="w-50"
                    :options="{name: inputName(locale), label: locale, value: getValue(locale), required: specification.required }"></f-text>
        </template>
    </div>

</template>

<script>
import FText from "../inputs/FText.vue";

export default {
    name: "SpecificationTextarea",
    components: {FText},
    props: {
        specification: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
            required: false
        },
        locales: {
            type: Array,
            required: ['ka']
        },
    },
    data() {
        return {
            translations: {}
        }
    },
    beforeMount() {
        this.locales.forEach(locale => this.translations[locale] = '');
        if (this.value && this.value.length) {
            this.locales.forEach(locale => this.translations[locale] = this.value[0].translations[locale].attribute);
        }
    },
    methods: {
        inputName(locale) {
            return `specification[input][${this.specification.id}][${locale}][attribute]`;
        },
        getValue(locale) {
            return this.translations[locale] || this.specification.value;
        }
    }
}
</script>

<style scoped>

</style>
