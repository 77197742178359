<template>
    <label class="fw-bold">{{ specification.name }}</label>
    <template v-for="item in specification.items" v-if="specification.items">
        <f-checkbox class="ps-3"
                    :options="{name: inputName(), value:item.id, label: item.name, type: 'radio',checked: isChecked(item.id), required: specification.required}"></f-checkbox>
    </template>
</template>

<script>
import FCheckbox from "../inputs/FCheckbox";

export default {
    name: "SpecificationRadio",
    components: {FCheckbox},
    props: {
        specification: {
            type: Object,
            required: true
        },
        items: {
            type: Array,
            required: false
        },
        value: {
            type: Array,
            required: false
        },
    },
    methods: {
        inputName() {
            return `specification[list][${this.specification.id}][]`;
        },
        isChecked(id) {
            if (this.value && this.value.length) {
                return !!this.value.find(item => item.id === id);
            }
            return false;
        }
    }
}
</script>

<style scoped>

</style>
