<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "VendorPicker",
    props: {
        items: {
            type: Object,
            required: true,
        },
        vendor: {
            type: Number,
            default: null,
        },
        location: {
            type: Number,
            default: null,
        }
    },
    data() {
        return {
            vendors: [],
            vendorLocations: [],
            vendorId: null,
            vendorLocationId: null,
            show: false
        }
    },
    mounted() {
        this.vendorId = this.vendor;
        this.vendorLocationId = this.location;
        this.vendors = this.items.map(item => {
            return {
                id: item.id,
                name: item.name,
                locations: item.locations,
                selected: item.id === this.vendorId
            }
        })

        if (this.vendorId) {
            this.vendorListUpdate(this.vendorId);
        }
        this.show = true;
    },
    methods: {
        vendorListUpdate(vendorId) {
            this.vendorId = vendorId;
            this.show = false;
            const selected = this.vendors.find(item => {
                return item.id === parseInt(vendorId);
            });
            if (selected && selected.locations) {
                this.vendorLocations = selected.locations;
            }
            if (!this.vendorLocationId && selected.locations.length)
                this.vendorLocationId = selected.locations[0].id;
            this.$nextTick(() => {
                this.show = true;
            })
        },
        vendorLocationUpdate(locationId) {
            this.vendorLocationId = locationId;
            this.show = false;

            this.$nextTick(() => {
                this.show = true;
            })
        }
    }
})
</script>

<template>
    <div v-if="show">
        <div class="mb-3">
            <f-select :items='vendors'
                      @update-select="vendorListUpdate($event)"
                      :options="{name:'vendor_id', required:true, label:'ვენდორი', value: vendorId}"></f-select>
        </div>

        <div class="mb-3">
            <f-select :items='vendorLocations'
                      @update-select="vendorLocationUpdate($event)"
                      :options="{name:'vendor_location_id', required:true, label:'ვენდორი მისამართი', value: vendorLocationId}"></f-select>
        </div>
    </div>
</template>

<style scoped>

</style>
