<template>
    {{ text }}
</template>
<script>
export default {
    name: 'TextEntry',
    props: ['text'],
}
</script>
<style scoped>

</style>
