<template>
    <label class="fw-bold">{{ specification.name }}</label>
    <f-select v-if="specification.items" :items="specification.items"
              :options="{name: inputName, label: false, value: getValue(), required: specification.required }"></f-select>
</template>

<script>
import FSelect from "../inputs/FSelect";

export default {
    name: "SpecificationList",
    components: {FSelect},
    props: {
        specification: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
            required: false
        },
    },
    computed: {
        inputName() {
            return `specification[list][${this.specification.id}]`;
        },
    },
    methods: {
        getValue() {
            if (this.value && this.value.length) {
                return this.value[0].id;
            }
        }
    }
}
</script>

<style scoped>

</style>
