<template>
    <a :href="route" class="btn btn-link">
        {{ name }}
    </a>
</template>
<script>
export default {
    name: 'ViewEntry',
    props: ['route', 'name'],
}
</script>
<style scoped>

</style>
