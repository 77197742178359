<template>
    <label class="fw-bold">{{ specification.name }}</label>
    <div class="d-flex gap-3">
        <template v-for="locale in locales">
            <f-input class="w-50"
                     :options="{name: inputName(locale),step: 0.0001, label: locale, value: getValue(locale), type:'number', required: specification.required }"></f-input>
        </template>
    </div>
</template>

<script>
import FInput from "../inputs/FInput";

export default {
    name: "SpecificationNumber",
    components: {FInput},
    props: {
        specification: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
            required: false
        },
        locales: {
            type: Array,
            required: ['ka']
        },
    },
    data() {
        return {
            translations: {}
        }
    },
    beforeMount() {
        this.locales.forEach(locale => this.translations[locale] = '');
        if (this.value && this.value.length) {
            this.locales.forEach(locale => this.translations[locale] = this.value[0].translations[locale].attribute);
        }
    },
    methods: {
        inputName(locale) {
            return `specification[input][${this.specification.id}][${locale}][attribute]`;
        },
        getValue(locale) {
            return this.translations[locale] || this.specification.value;
        }
    }
}
</script>

<style scoped>

</style>
