<template>
    <div class="input-group">
        <input type="text" class="form-control bg-transparent text-light" :readonly="loading" v-model="name"
               placeholder="New Folder" @keyup.enter="newFolder">
        <button class="btn btn-primary" @click.prevent="newFolder">
            <i class="bi" :class="loading?'bi-hourglass-split':'bi-folder-plus'"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: "FilePickerNewFolder",
    emits: ['create'],
    data() {
        return {
            name: '',
            loading: false,
        }
    },
    methods: {
        newFolder() {
            if (this.loading) return;
            if (!this.name.length) return this.$toast.error(this.$t('file.picker.folder.error'));

            this.loading = true;
            axios.post('/infy/filemanager/folder/create', {name: this.name}).then(response => {
                this.loading = false;
                this.$toast.success(this.$t('file.picker.folder.created', [this.name]));
                this.name = '';
                setTimeout(() => {
                    this.$emit('create', true);
                }, 1000);

            }).catch(() => {
                this.$emit('create', false);
                this.loading = false;
                this.name = '';
                this.$toast.error(this.$t('file.picker.folder.error'))
            });
        }
    }
}
</script>

<style scoped>
</style>
