import * as VueSelect from "vue-select";
import {createApp} from 'vue';
import {createI18n} from 'vue-i18n/dist/vue-i18n.cjs'
import en from '../lang/en.json';
import ka from '../lang/ka.json';
import {Toaster} from "@incuca/vue3-toaster";

const i18n = createI18n({
    globalInjection: true, locale: document.documentElement.lang, messages: {en, ka}
})
const app = createApp({
    mounted() {
        const toast = this.$toast;
        !function (toast) {
            "use strict";
            const t = document.querySelectorAll(".needs-validation");
            Array.prototype.slice.call(t).forEach(function (t) {
                t.addEventListener("submit", function (e) {
                    t.querySelectorAll(':invalid').forEach(i => {
                        toast.error(`${i.getAttribute('label')} ERROR`);
                    })
                    t.checkValidity() || (e.preventDefault(), e.stopPropagation()), t.classList.add("was-validated")
                }, !1)
            })
        }(toast);
    }
});
app.component('VSelect', VueSelect)
app.component('SpecificationPicker', require('./components/specifications/SpecificationPicker').default);
app.component('SpecificationInput', require('./components/specifications/SpecificationInput').default);
app.component('SpecificationNumber', require('./components/specifications/SpecificationNumber').default);
app.component('SpecificationList', require('./components/specifications/SpecificationList').default);
app.component('SpecificationCheckbox', require('./components/specifications/SpecificationCheckbox').default);
app.component('SpecificationRadio', require('./components/specifications/SpecificationRadio').default);
app.component('FInput', require('./components/inputs/FInput').default);
app.component('FCheckbox', require('./components/inputs/FCheckbox').default);
app.component('FTranslation', require('./components/inputs/FTranslation').default);
app.component('FSelect', require('./components/inputs/FSelect').default);
app.component('FText', require('./components/inputs/FText').default);
app.component('FEditor', require('./components/inputs/FEditor').default);
app.component('FNested', require('./components/inputs/FNested').default);
app.component('FilePicker', require('./components/file-picker/FilePicker').default);
app.component('DraggableList', require('./components/DraggableList').default);
app.component('LiveData', require('./components/LiveData').default);
app.component('LiveDataColumn', require('./components/LiveDataColumn').default);
app.component('LiveGridPagination', require('./components/ecommerece/LiveGridPagination.vue').default);
app.component('MainMenu', require('./components/MainMenu').default);
app.component('VendorPicker', require('./components/VendorPicker').default);
app.component('ProductPriceCalculator', require('./components/ProductPriceCalculator.vue').default);

app.use(Toaster.install).use(i18n).mount("#app");
