<template>
    <div class="accordion" id="storage-tree">
        <div class="accordion-item border-0" v-for="(storage, name) in storages" :key="name">
            <h2 class="accordion-header">
                <button class="accordion-button rounded-0 px-3" type="button" data-bs-toggle="collapse"
                        :class="{collapsed: first !== name}"
                        @click="$emit('selectStorage', name)"
                        :data-bs-target="`#${name}-public`">
                    <i class="bi me-2" :class="storage.icon"></i>
                    {{ name }}
                </button>
            </h2>
            <div :id="`${name}-public`" class="accordion-collapse collapse" :class="{show: first === name}"
                 data-bs-parent="#storage-tree">
                <div class="accordion-body p-0 pb-2" v-if="storage.items.length">
                    <file-picker-storage-folder v-on:select-folder="selectFolder($event, storage)"
                                                :folders="storage.items"></file-picker-storage-folder>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilePickerStorageFolder from "./FilePickerStorageFolder";

export default {
    name: "FilePickerStorage",
    components: {FilePickerStorageFolder},
    emits: ['selectStorage', 'selectFolder'],
    props: {
        storages: {
            type: Object,
            required: true
        }
    },
    methods: {
        selectFolder(folder, storage) {
            this.updateFolder(storage.items, folder.id)
            this.$emit('selectFolder', folder);
        },
        updateFolder(folders, id) {
            return folders.map(folder => {
                folder.selected = folder.id === id;
                if (folder.items.length) {
                    folder.items = this.updateFolder(folder.items, id);
                }
                return folder
            })
        }
    },
    computed: {
        first: {
            get() {
                return Object.keys(this.storages)[0];
            }
        }
    }
}
</script>

<style scoped>

</style>
