<template>
    <a :href="item.attribute" class="nav-link px-lg-0">
        <i :class="item.icon"></i>
        {{ item.title }}
    </a>
</template>

<script>
export default {
    name: "MainMenuItem",
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
