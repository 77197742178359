<template>
    <label class="btn btn-primary me-1" :class="progress && 'breath'">
        <template v-if="progress > 1">
            <i class="bi" :class="progress%2?'bi-hourglass-top':'bi-hourglass-bottom'"></i>
            {{ $t('file.picker.upload.uploading', [progress]) }}
        </template>
        <template v-else>
            <i class="bi bi-upload"></i> {{ $t('file.picker.file.upload') }}
        </template>
        <input type="file" multiple="multiple" class="d-none" @change="upload($event)">
    </label>
</template>

<script>
import axios from 'axios';

export default {
    name: "FilePickerFileUpload",
    emits: ['uploadDone'],
    props: {
        dirId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            progress: 0,
        }
    },
    methods: {
        upload(event) {
            let formData = new FormData();
            let files = '';
            event.target.files.forEach(file => {
                files += file.name + ', ';
                formData.append('files', file)
            });
            event.target.value = '';
            axios.post('/infy/filemanager/upload',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        dir_id: this.dirId
                    },
                    onUploadProgress: progressEvent => this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            ).then(() => {
                    this.$toast.success(this.$t('file.picker.upload.done', [files.slice(0, -2)]));
                    setTimeout(() => this.progress = 0, 1000)
                    this.$emit('uploadDone');
                }
            ).catch((err) => {
                this.progress = 0;
                this.$toast.error(this.$t('file.picker.upload.error'));
            });
        }
    }
}
</script>

<style scoped>
.breath {
    animation: breath 2s ease infinite;
}
</style>
