<template>
    <div class="bg-transparent border-0 d-flex flex-column align-items-center p-0 m-0 mw-100" v-for="folder in folders">
        <div role="button" @click="select(folder)" class="text-truncate p-2 w-100"
             :class="{'bg-primary':folder.selected}">
            <i class="bi bi-chevron-down" data-bs-toggle="collapse" :data-bs-target="`#fp-${folder.id}`"
               :class="open ? 'bi-chevron-up':'bi-chevron-down'"
               @click.stop="open = !open"
               v-if="folder && folder.items"></i>
            <i class="bi bi-folder2-open mx-1"></i> <sup>{{ folder.name }}</sup>
        </div>
        <div :id="`fp-${folder.id}`" class="collapse list-group mw-100 collapse ps-3 w-100 mb-2" v-if="folder.items">
            <file-picker-storage-folder v-on:select-folder="select($event)"
                                        :folders="folder.items"></file-picker-storage-folder>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilePickerStorageFolder",
    props: ['folders'],
    emits: ['selectFolder'],
    data() {
        return {
            selected: false,
            open: false,
        }
    },
    methods: {
        select(folder) {
            this.$emit('selectFolder', folder);
        }
    }
}
</script>

<style scoped lang="scss">
div[role="button"] {
    background: transparent;

    &:hover {
        background: var(--bs-secondary);
    }
}
</style>
