<template v-cloak>
    <div>
        <button class="btn btn-warning" :class="{breath: !isDisabled}" :disabled="isDisabled" @click="saveChanges()">
            {{ $t('draggable.save') }}
        </button>

        <div v-if="changesSaved">
            <div class="alert alert-success alert-dismissible fade show mt-2" role="alert">
                <i class="bi bi-check fs-4"></i> {{ $t('draggable.saved') }}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>

    </div>

    <draggable-nested-list :edit-route="editRoute" :items="itemList" v-if="itemList && showList" :nested="nested"
                           key="itemList"
                           @save-changes="update($event)"></draggable-nested-list>
    <div>
        <button class="btn btn-warning" :class="{breath: !isDisabled}" :disabled="isDisabled" @click="saveChanges()">
            {{ $t('draggable.save') }}
        </button>

        <div v-if="changesSaved">
            <div class="alert alert-success alert-dismissible fade show mt-2" role="alert">
                <i class="bi bi-check fs-4"></i> {{ $t('draggable.saved') }}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>

    </div>
</template>

<script>
import DraggableNestedList from "./DraggableNestedList";
import axios from "axios";

export default {
    name: "DraggableList",
    components: {DraggableNestedList},
    props: {
        route: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        editRoute: {
            type: String,
        },
        nested: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            isDisabled: true,
            changesSaved: false,
            itemList: null,
            showList: true
        }
    },
    mounted() {
        this.itemList = this.items;
    },
    methods: {
        update(list) {
            this.updatePosition(list, 0)
            this.updateParentIds(list, null)
            // this.itemList = list;
            this.isDisabled = false;
        },
        saveChanges() {
            this.isDisabled = true;
            this.showList = false;
            axios.post(this.route, {list: this.itemList}).then(response => {
                if (response.data.result) {
                    this.changesSaved = true;
                    this.showList = true;
                    this.itemList = [...this.itemList.filter(item => !item.deleted)];
                    return this.$toast.success(this.$t('layout.success'));
                }
                this.$toast.error(this.$t('layout.unsuccessful'));
            }).catch(err => {
                return this.$toast.error(err);
            })
        },
        updatePosition(items, i) {
            items.map(item => {
                item.position = ++i;
                if (item.items && item.items.length) {
                    i = this.updatePosition(item.items, ++i);
                }
            })
            return i;
        },
        updateParentIds(items, id) {
            console.log(items);
            return items.map(item => {
                item.parentId = id;
                if (item.items.length) {
                    item.items = this.updateParentIds(item.items, item.id);
                }
                return item;
            })
        }

    }
}
</script>

<style scoped>
.breath {
    animation: breath 1s ease infinite;
}
</style>
