<script>

export default {
    name: "ProductPriceCalculator",
    props: {
        supplierPrice: {
            type: Number,
            default: 0
        },
        supplierPercent: {
            type: Number,
            default: 0
        },
        initialPrice: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            update: '1',
            startPrice: 0,
            startPercent: 0,
            finalPrice: 0,
        }
    },
    mounted() {
        this.startPrice = this.supplierPrice;
        this.startPercent = this.supplierPercent;
        this.finalPrice = this.initialPrice;
        this.$nextTick(()=>{
            this.update = '';
        })
    },
    methods: {
        updatePrice(method, value) {
            this.update = method;
            if (method === 'supplier_price') {
                this.startPrice = parseFloat(value);
                this.finalPrice = 0;
                if (this.startPercent) {
                    this.finalPrice = Math.round(this.startPrice + this.startPrice * this.startPercent / 100);
                }
            } else if (method === 'supplier_percent') {
                this.startPercent = parseFloat(value);
                this.finalPrice = 0;
                if (this.startPrice) {
                    this.finalPrice = Math.round(this.startPrice + this.startPrice * this.startPercent / 100);
                }
            } else {
                this.finalPrice = parseFloat(value);
                if (this.startPrice) {
                    this.startPercent = Math.round((this.finalPrice / this.startPrice * 100) - 100);
                }
            }


            this.$nextTick(()=>{
                this.update = '';
            })
        }
    }

}
</script>

<template>
    <f-input class="mb-3"
             v-if="update === 'supplier_price' || update === ''"
             @update-value="updatePrice('supplier_price', $event)"
             :options="{type:'number',step:0.01, name:'supplier_price',label:'მომწოდებლის ფასი',required:true, value: startPrice}"></f-input>
    <f-input class="mb-3"
             v-if="update === 'supplier_percent' || update === ''"
             @update-value="updatePrice('supplier_percent', $event)"
             :options="{type:'number',step:0.01, name:'supplier_percent',label:'პროცენტი',required:true, value: startPercent}"></f-input>
    <f-input class="mb-3"
             v-if="update === 'initial_price' || update === ''"
             @update-value="updatePrice('initial_price', $event)"
             :options="{type:'number',step:0.01,name:'initial_price',label:'საწყისი ფასი',required:true, value: finalPrice}"></f-input>
</template>

<style scoped>

</style>
