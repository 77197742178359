<template>
    <tr v-for="row in rows">
        <td v-for="column in columns" :class="column.class">
            <template v-if="column.type === 'text'">{{ row[column.name] }}</template>
            <template v-else-if="column.type === 'html'" v-html='row[column.name]'></template>
            <template v-else-if="column.type === 'vue'">
                <template v-for="line in row[column.name]" :key="line">
                    <component v-if="line.type === 'component'" :is="line.attr" v-bind="line.props"
                               @update="updateHandler($event)"/>
                    <span v-else-if="line.type ==='html'" v-html="line.attr"></span>
                    <span v-else>{{ line.attr }}</span>
                </template>
            </template>
        </td>
    </tr>
</template>

<script>

import DeleteEntry from "./DeleteEntry";
import IconComponent from "./IconComponent.vue";
import EditEntry from "./EditEntry.vue";
import ViewEntry from "./ViewEntry.vue";
import TextEntry from "./TextEntry.vue";
import QuickViewEntry from "./QuickViewEntry.vue";
import PositionComponent from "./PositionComponent.vue";

export default {
    name: "LiveDataColumn",
    emits: ['update'],
    components: {
        TextEntry,
        DeleteEntry,
        EditEntry,
        ViewEntry,
        QuickViewEntry,
        IconComponent,
        PositionComponent,
    },
    props: {
        rows: {
            type: Array,
            default: []
        },
        columns: {
            type: Array,
            default: []
        },
    },
    methods: {
        updateHandler(e) {
            this.$emit("update", e);
        }
    }
}
</script>

<style scoped>

</style>
