<template>
    <ul class="nav flex-column w-100">
        <template v-for="item in items">
            <li class="nav-item">
                <template v-if="item.items && item.items.length">
                    <a class="nav-link px-lg-0" data-bs-toggle="collapse" :href="'#menu-item-'+item.id"
                       role="button">
                        <i :class="item.icon"></i>
                        {{ item.title }}
                        <i class="bi bi-chevron-down float-end"></i>
                    </a>
                    <span class="collapse" :id="'menu-item-'+item.id">
                        <main-menu class="ps-3" :items="item.items"></main-menu>
                    </span>
                </template>
                <a :href="item.attribute" class="nav-link px-lg-0" v-else>
                    <i :class="item.icon"></i>
                    {{ item.title }}
                </a>
            </li>
        </template>
    </ul>
</template>

<script>
import MainMenuItem from "./MainMenuItem";

export default {
    name: "MainMenu",
    components: {MainMenuItem},
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
