<template>
    <slot name="filters" :filter="filterData"></slot>
    <div :class="{'table-responsive': table.responsive}">
        <table class="table" :class="{loading}">
            <thead>
            <tr>
                <th v-for="column in table.columns" :style="{width: column.width ?? 'auto'}" class="align-middle">
                    <a href="#" class="me-2" v-if="column.orderable" @click.prevent="order(column)">
                        <i class="bi"
                           :class="{'bi-sort-down': column.order === 1, 'bi-sort-up': column.order === -1, 'bi-filter': !column.order}"></i>
                    </a>
                    {{ column.title }}
                </th>
            </tr>
            <tr v-if="table.search">
                <th v-for="column in table.columns" :style="{width: column.width ?? 'auto'}" class="align-middle">
                    <div v-if="column.searchable" class="d-flex align-items-center">
                        <input type="text" v-model="column.search" @keyup="updateTable()"
                               :placeholder="column.searchable"
                               class="form-control form-control-sm w-auto">
                        <a href="#" @click.prevent="column.search = '';updateTable()"
                           v-show="column.search && column.search.length"
                           class="bi bi-x-circle text-secondary opacity-50 ms-3 fs-4"></a>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <LiveDataColumn :rows="table.filtered" v-if="table.filtered.length"
                            :columns="table.columns" @update="updateHandler($event)"></LiveDataColumn>
            <tr v-else>
                <td :colspan="table.columns ? table.columns.length: 0" class="text-center">
                    Empty
                </td>
            </tr>
            </tbody>
            <tfoot v-if="table.foot">
            <tr>
                <th v-for="column in table.columns" :style="{width: column.width ?? 'auto'}" class="align-middle">
                    <a href="#" class="d-inline-block me-2" v-if="column.orderable" @click.prevent="order(column)">
                        <i class="bi"
                           :class="{'bi-sort-down': column.order === 1, 'bi-sort-up': column.order === -1, 'bi-filter': !column.order}"></i>
                    </a>
                    {{ column.title }}
                </th>
            </tr>
            <tr>
                <th v-for="column in table.columns" :style="{width: column.width ?? 'auto'}" class="align-middle">
                    <div v-if="column.searchable">
                        <input type="text" v-model="column.search" @keyup="updateTable()"
                               :placeholder="column.searchable"
                               class="form-control form-control-sm w-auto">
                    </div>
                </th>
            </tr>
            </tfoot>
        </table>
        <div class="row align-items-center">
            <div class="col">
                {{
                    $t('table.result', {
                        from: pagination.offset + 1,
                        to: pagination.offset + pagination.limit,
                        entries: pagination.total
                    })
                }}
            </div>
            <div class="col-auto ms-auto">
                <live-grid-pagination :current="pagination.page + 1" :last="pagination.pages" @change-page="changePage($event)"></live-grid-pagination>
            </div>
            <div class="col-auto ms-auto">
                <select class="form-select w-auto" @change="changePage(parseInt($event.target.value))"
                        :title="$t('table.pages')">
                    <option v-for="n in pagination.pages">{{ n }}</option>
                </select>
            </div>
            <div class="col-auto ms-auto">
                <select class="form-select w-auto" v-model.number="pagination.limit"
                        @change="updateLimit()"
                        :title="$t('table.limits')">
                    <option v-for="limit in table.limits">{{ limit }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LiveDataColumn from "./LiveDataColumn";
import LiveGridPagination from "./ecommerece/LiveGridPagination.vue";

export default {
    name: "LiveData",
    components: {LiveGridPagination, LiveDataColumn},
    props: {
        data: {
            type: Array,
            default: []
        },
        url: {
            type: String,
            required: true
        },
        columns: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            table: {
                search: false,
                foot: false,
                responsive: false,
                serverSide: true,
                limits: [10, 20, 50, 100],
                rows: [],
                filtered: [],
            },
            default: {
                column: {
                    name: '',
                    title: '',
                    orderable: false,
                    searchable: false,
                    order: false,
                    search: null,
                    width: null,
                    class: null,
                    type: 'text'
                }
            },
            pagination: {
                page: 0,
                total: 0,
                filtered: 0,
                pages: 0,
                offset: 0,
                limit: 10,
                firstPage: () => !this.pagination.page,
                lastPage: () => this.pagination.page === this.pagination.pages - 1
            },
            filters: {},
            orders: {},
            filterDelay: null,
            loading: true,
        }
    },
    mounted() {
        const data = this.data || {};
        this.table.search = data.hasOwnProperty('columns') && !!this.columns.filter(column => column.searchable).length;
        Object.assign(this.table, data);
        this.table.columns = this.columns.map(column => Object.assign({}, this.default.column, column))
        this.table.rows = data.rows || [];
        this.updateTable();
    },
    methods: {
        filterData(name, value, toggle = false) {
            clearTimeout(this.filterDelay);
            this.loading = true;
            if (!value.length || this.filters.hasOwnProperty(name) && toggle) {
                delete this.filters[name];
            } else {
                this.filters[name] = value;
            }
            this.filterDelay = setTimeout(() => {
                this.updateTable();
            }, 700);
        },
        loadServerData() {
            this.loading = true;
            axios.post(this.url, {
                filters: this.filters,
                orders: this.orders,
                limit: this.pagination.limit,
                offset: this.pagination.offset,
            }).then(response => {
                this.table.filtered = response.data.rows;
                this.pagination.total = response.data.pagination.total;
                this.pagination.filtered = response.data.pagination.filtered;
                this.pagination.pages = Math.ceil(this.pagination.total / this.pagination.limit);
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            })
        },
        updateTable() {
            if (this.table.serverSide) {
                this.loadServerData();
                return;
            }
            let data = this.table.rows;
            this.columns.map(column => {
                if (column.hasOwnProperty('search') && column.search && column.search.length) {
                    data = data.filter(row => (row[column.name] + '').indexOf(column.search) > -1);
                }
                if (column.order) {
                    const name = column.name;
                    const order = column.order;
                    data = data
                        .sort((a, b) => (a[name] > b[name]) ? -order : ((b[name] > a[name]) ? order : 0));
                }
            })
            this.loading = false;
            this.table.filtered = data.slice(this.pagination.offset, this.pagination.offset + this.pagination.limit);
            this.updatePagination(data ? data.length : 0);
        },
        order(column) {
            const order = column.order === 1 ? -1 : 1;
            this.columns.map(col => col.order = column.name === col.name ? order : 1)
            this.orders[column.name] = order;
            this.updateTable();

        },
        changePage(page) {
            if (page < 1 || page > this.pagination.pages || page === this.pagination.page - 1) return;
            this.pagination.page = page - 1;
            this.pagination.offset = this.pagination.page * this.pagination.limit;
            this.updateTable();
        },
        updatePagination(filtered = 0) {
            this.pagination.total = this.table.rows ? this.table.rows.length : 0;
            this.pagination.filtered = filtered;
            this.pagination.pages = Math.ceil(this.pagination.filtered / this.pagination.limit);
        },
        updateLimit() {
            this.pagination.page = 0;
            this.pagination.offset = 0;
            this.updateTable();
        },
        updateHandler(e) {
            this.loading = false;
            if (e === 'loading') {
                this.loading = true;
            } else if (e === 'finish') {
                this.loadServerData();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.table {

    tbody {
        position: relative;
    }

    &.loading tbody:after {
        content: 'Loading ...';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        border-radius: 10px;
        background: rgba(#000, 0.8);
    }
}
</style>
