<script>
import axios from "axios";

export default {
    name: "PositionComponent",
    props: {
        id: {
            type: Number
        },
        position: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: 'block'
        },
        url: {
            type: String
        },
        max: {
            type: Number
        }
    },
    methods: {
        updatePosition(n) {
            this.$emit("update", 'loading');
            axios.post(this.url, {id: this.id, position: this.position + n, type: this.type}).then(response => {
                if (response.data.result) {
                    this.$emit("update", 'finish');
                    return this.$toast.success(this.$t('layout.success'))
                }
                this.$emit("update", 'false');
                this.$toast.error(this.$t('layout.unsuccessful'))
            })

        }
    }
}
</script>

<template>
    <div class="btn-group gap-1">
        <button class="btn btn-primary" @click="updatePosition(1)" :disabled="position >= max-1">
            <i class="bi bi-arrow-up"></i>
        </button>
        <button class="btn btn-primary" @click="updatePosition(-1)" :disabled="position < 1">
            <i class="bi bi-arrow-down"></i>
        </button>
    </div>
</template>

<style scoped>

</style>
