<script>

export default {
    name: 'EditEntry',
    props: ['route']
}
</script>

<template>
    <a :href="route" class="btn btn-success">
        <i class="bi bi-pencil"></i>
    </a>
</template>

<style scoped>

</style>
