<template>
    <draggable
        class="list-group mx-0"
        :class="{'py-1': !items.length}"
        @end="update()"
        :swapThreshold='.4'
        :animation="500"
        :invertSwap="false"
        :itemKey="'name'"
        :handle="'.handle'"
        :group="{name: 'group'}"
        :tag="'ul'"
        :list="items"
        v-if="items"
    >
        <template #item="{ element }">
            <li class="list-group-item border-0 p-0">
                <div
                    class="d-flex p-1 ps-2 rounded justify-content-between align-items-center border border-dark"
                    :class="{ odd: level, even: !level, 'bg-danger':element.deleted}">
                    <div class="d-flex align-items-center">
                        <div class="mt-1 me-2" role="button" :class="{handle: !element.deleted}">
                            <i class="bi bi-arrows-move fs-4" :class="{'opacity-25': element.deleted}"></i>
                        </div>
                        {{ element.name || element.title }}
                    </div>
                    <div class="btn-group" v-if="canRestore">
                        <button class="btn" :class="element.visible ? 'btn-info':'btn-warning'"
                                @click="toggleVisible(element)"
                                v-if="!element.deleted && element.hasOwnProperty('visible')">
                            <i class="bi" :class="element.visible ? 'bi-eye':'bi-eye-slash' "></i>
                        </button>
                        <delete-entry :slug="element.slug"
                                      :title="element.name || element.title"
                                      :key="element.slug"
                                      v-if="!element.deleted"
                                      @delete="toggleDelete($event)"/>
                        <button class="btn btn-success" @click="toggleDelete(element.slug)"
                                v-if="element.deleted">
                            <i class="bi bi-arrow-counterclockwise"></i> {{ $t('layout.restore') }}
                        </button>
                        <edit-entry :route="elementEditRoute(element.id)" v-if="!element.deleted"></edit-entry>
                    </div>
                </div>
                <draggable-nested-list class="ms-3"
                                       :child="true"
                                       :level="!level"
                                       :items="element.items"
                                       :edit-route="editRoute"
                                       :can-restore="!element.deleted"
                                       @save-changes="update($event)"
                                       v-if="nested"/>
            </li>
        </template>
    </draggable>
</template>
<script>
import draggable from "vuedraggable";
import DeleteEntry from "./DeleteEntry";
import EditEntry from "./EditEntry.vue";

export default {
    name: "DraggableNestedList",
    emits: ['save-changes'],
    props: {
        child: {
            type: Boolean,
            default: false,
        },
        items: {
            required: true,
            type: Array,
        },
        level: {
            type: Boolean,
            default: false,
        },
        canRestore: {
            type: Boolean,
            default: true,
        },
        editRoute: {
            type: String,
        },
        nested: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        EditEntry,
        DeleteEntry,
        draggable
    },
    methods: {
        update() {
            this.$emit('save-changes', this.items);
        },
        toggleDelete(item) {
            let _item = this.items.find(i => i.slug === item);
            _item.deleted = _item.hasOwnProperty('deleted') ? !_item.deleted : true;
            if (_item.items && _item.items.length)
                _item.items = this.toggleDeleteItems(_item.items, _item.deleted);
            this.$emit('save-changes', this.items);
        },
        toggleDeleteItems(items, isDeleted) {
            return items.map(item => {
                item.items = this.toggleDeleteItems(item.items, isDeleted)
                item.deleted = isDeleted;
                return item;
            })
        },
        toggleVisible(item) {
            let _item = this.items.find(i => i.id === item.id);
            _item.visible = !_item.visible;
            if (_item.items && _item.items.length)
                _item.items = this.toggleVisibilityItems(_item.items, _item.visible);
            this.$emit('save-changes', this.items);
        },
        toggleVisibilityItems(items, isVisible) {
            return items.map(item => {
                item.items = this.toggleVisibilityItems(item.items, isVisible)
                item.visible = isVisible;
                return item;
            })
        },
        elementEditRoute(slug) {
            if (!this.editRoute) return '';
            return this.editRoute.replace('_id', slug);
        },
    },
    mounted() {
        this.items.map(item => {
            if (!item.hasOwnProperty('items')) {
                item.items = [];
            }
            return item;
        })
    }
};
</script>
<style scoped lang="scss">
.odd {
    background-color: var(--bs-secondary);
}

.list-group-item {
    & > div:hover {
        background-color: var(--bs-teal);
    }

    &:first-child {
        margin-top: .25rem;
    }
}
</style>
