<template>
    <i class="fs-1" :class="className"></i>
</template>

<script>

export default {
    name: "IconComponent",
    props: {
        pinned: {
            type: Number,
            default: ""
        }
    },
    data(){
      return {
          className: 'bi-check2 text-success'
      }
    },
    mounted() {
        if(!this.pinned)
            this.className = 'bi-x text-danger';
    }
}
</script>

<style scoped>

</style>
