<template>
    <div class="card text-center text-light border-light position-relative align-items-center"
         :class="{'border-light':file.selected, 'border-transparent': !file.selected}"
         :title="file.name"
         @click=" this.$emit('select', {file: i, selected: !file.selected})"
         role="button">
        <div class="check-file" v-if="config.multiple || file.selected">
            <i class="bi text-primary" :class="file.selected ?'bi-check-circle-fill':'bi-circle-fill'"></i>
        </div>
        <div class="my-auto  px-3 w-100">
            <img :src="file.url" v-if="file.type === 'image'" class="fp-image mw-100 rounded" alt="">
            <i class="bi bi-file-earmark display-1" v-else></i>
        </div>
        <p class="card-title small my-3 text-truncate d-block px-3 w-100">{{ file.name }}</p>
    </div>
</template>

<script>
export default {
    name: "FilePickerFile",
    props: ['i', 'file', 'options'],
    emits: ['select'],
    data() {
        return {
            config: {
                multiple: false
            }
        }
    }
}
</script>

<style scoped lang="scss">
.card {
    background: transparent;
    height: 210px;
    width: 100%;
    align-items: center;

    &:hover {
        background: var(--bs-secondary);
    }

    &.border-transparent {
        border-color: transparent !important;
    }
}

.fp-image {
    height: 110px;
    object-fit: contain;
}

.check-file {
    position: absolute;
    top: .5rem;
    right: .5rem;
}
</style>
