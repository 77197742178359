<template>
    <button class="btn btn-primary" @click="showModal" data-bs-toggle="modal" data-bs-target="#orderModal">
        {{ text }}
        <i class="bi" :class="icon" v-if="icon && icon.length"></i>
    </button>
</template>
<script>
export default {
    name: 'QuickViewEntry',
    props: ['route', 'name', 'text', 'icon', 'slug'],
    methods: {
        showModal() {
            const url_ob = new URL(document.URL);
            url_ob.hash = '#';
            document.location.href = url_ob.href;
            setTimeout(()=>{
                url_ob.hash = `#${this.slug}`;
                document.location.href = url_ob.href;
            }, 100);
        }
    }
}
</script>
<style scoped>

</style>
